import { Button } from "../../components";
import mdLogo from "../../assets/logo-full.svg";
import twitterLogo from "../../assets/twitter-logo.svg";
import mediumLogo from "../../assets/medium-logo.svg";
import "./styles.scss";

function Promo() {
  return (
    <div className="promo-container">
      <img alt="logo" src={mdLogo} className="logo" />
      <div className="promo-text-container">
        <p>
          We’re building a platform that allows creators to upload a collection
          of NFTs to be released at a later date.{" "}
          <b>NFT Fans get ready to stream your “Box Breaks” to the world!</b>
        </p>

        <Button
          onClick={() => {
            window.location.href =
              "https://mysterydropapp.substack.com/welcome";
          }}
          text="Subscribe for Updates"
        />
      </div>
      <div className="promo-social-container">
        <a href="https://twitter.com/MysteryDropApp">
          <img alt="promo" src={twitterLogo} />
        </a>
        <a href="https://ipatka.medium.com/nft-box-breaks-how-to-use-the-unique-properties-of-ipfs-content-addressing-to-sell-nfts-before-365b5b1f1ed0">
          <img alt="promo" src={mediumLogo} />
        </a>
      </div>
    </div>
  );
}

export default Promo;
